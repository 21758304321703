.create-receipt-wrapper {
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 65px);
}

.type-selector {
  margin: 20px 0;
  text-align: left;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
}

.add-item-button {
  margin: 30px 0;
  padding: 10px 25px;
  border: none;
  background: darkcyan;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  color: white;
  font-size: 20px;

  .button-icon {
    margin-right: 7.5px;
  }

  &--disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

// filepond
.filepond--wrapper {
  width: 100%;
}

// INPUT FIELD
.css-input {
  padding: 15px;
  font-size: 16px;
  border-width: 0px;
  border-color: #CCCCCC;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(66,66,66,.25);
  text-align: center;
  max-width: 100%;
  min-width: 300px;
}

.css-input:focus {
  outline:none;
}

// CHECKBOXES
.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 37px;
  margin-bottom: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 16px;
}
  .control input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
.control_indicator {
  position: absolute;
  top: 0px;
  left: 0;
  height: 30px;
  width: 30px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 20px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #FCF49B;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #FCF49B;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 2;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 12px;
  top: 7px;
  width: 4px;
  height: 10px;
  border: solid #0E4749;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 2.577rem;
  height: 2.5rem;
  margin-left: -5px;
  margin-top: -5px;
  background: #0E4749;
  border-radius: 3rem;
  opacity: 0.5;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  // animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  // animation-name: s-ripple-dup;
}