@import "./styles/colors.scss";

.App {
  text-align: center;
  color: $color5;

  .logo-wrapper {
    background: $logo-bg;
    padding: 5px 0;
    height: 55px;
    width: 100%;
    position: fixed;
    z-index: 100;

    img {
      width: 100px;
    }
  }

  .all-content {
    padding-top: 65px;
  }
}


h1, h2, h3 {
  color: $color5;
}

.new-receipt-modal {
  position: fixed;
  width: 100%;
  background: white;
  height: calc(100vh - 65px);
  top: 65px;
  right: 0;
}

.add-new-receipt-button {
  color: darkcyan;
  position: fixed;
  right: 20px;
  bottom: 20px;
  box-shadow: 0px 0px 16px -1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border: 0;
  background: white;
  z-index: 100;
}
