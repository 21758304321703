/* RGB */
// https://coolors.co/7ddf64-000000-0e4749-f3efe0-211a1e
$color1: #FCF49B;
$color2: #000000;
$color3: #0E4749;
$color4: #F3EFE0;
$color5: #163140;

$white: #fff;

$logo-bg: $color5;