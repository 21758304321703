@import "../styles/colors.scss";

.receipt-list-wrapper {
  color: $color3;
  font-weight: bold;
  padding: 15px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;

  .list-item {
    margin: 0 0 15px 0;
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 16px -1px rgba(0,0,0,0.3);
    position: relative;
    width: 100%;
    border-radius: 5px;

    h3 {
      margin-bottom: 0;
      padding: 0 10px;
    }

    .small {
      font-size: 12px;
    }

    .receipt-image {
      width: 100%;
      height: 350px;
      background-size: cover;
      background-position: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .remove-receipt {
      background: white;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      &__icon {
        margin-top: 7px;
      }
    }
  }
}
